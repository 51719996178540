// Generated by Framer (9f979fb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["YxF_WSAfu", "zCeCYiyza"];

const serializationHash = "framer-iISVA"

const variantClassNames = {YxF_WSAfu: "framer-v-mvz9yw", zCeCYiyza: "framer-v-fepxun"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 200, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "zCeCYiyza", Menu: "YxF_WSAfu"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YxF_WSAfu", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YxF_WSAfu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16y2uw0 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {
const res = await XKaY3MrGu(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 13, intrinsicWidth: 16}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-mvz9yw", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"YxF_WSAfu"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap16y2uw0} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", ...style}} variants={{zCeCYiyza: {backgroundColor: "rgba(255, 255, 255, 0)"}}} {...addPropertyOverrides({zCeCYiyza: {"data-framer-name": "Close", background: undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-q3gg1"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"LaKb2LduE"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{zCeCYiyza: {backgroundColor: "rgb(255, 255, 255)", rotate: 45}}}/><motion.div className={"framer-1dcl4pm"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"pfK3tetEX"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{zCeCYiyza: {backgroundColor: "rgb(255, 255, 255)", rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-iISVA[data-border=\"true\"]::after, .framer-iISVA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iISVA.framer-hbtsii, .framer-iISVA .framer-hbtsii { display: block; }", ".framer-iISVA.framer-mvz9yw { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 44px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 44px; }", ".framer-iISVA .framer-q3gg1, .framer-iISVA .framer-1dcl4pm { flex: none; height: 2px; overflow: hidden; position: relative; width: 20px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-iISVA.framer-mvz9yw { gap: 0px; } .framer-iISVA.framer-mvz9yw > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-iISVA.framer-mvz9yw > :first-child { margin-top: 0px; } .framer-iISVA.framer-mvz9yw > :last-child { margin-bottom: 0px; } }", ".framer-iISVA.framer-v-fepxun.framer-mvz9yw { height: 39px; width: 48px; }", ".framer-iISVA.framer-v-fepxun .framer-q3gg1 { left: calc(50.00000000000002% - 31px / 2); position: absolute; top: calc(51.2820512820513% - 2px / 2); width: 31px; z-index: 1; }", ".framer-iISVA.framer-v-fepxun .framer-1dcl4pm { width: 31px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zCeCYiyza":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerH1VjNleEc: React.ComponentType<Props> = withCSS(Component, css, "framer-iISVA") as typeof Component;
export default FramerH1VjNleEc;

FramerH1VjNleEc.displayName = "Icon";

FramerH1VjNleEc.defaultProps = {height: 44, width: 44};

addPropertyControls(FramerH1VjNleEc, {variant: {options: ["YxF_WSAfu", "zCeCYiyza"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerH1VjNleEc, [])